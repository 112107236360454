import { authToken, getDataUser } from "./core/auth.service";
import { ROUTING_CONSTANT } from "./core/constant";
import { CONSTANT } from "./core/static-constant";

export const summaryPageTopMenu = {
  intro: {
    id: "intro",
    text: "Intro",
    path: "#intro",
    icon: "Vrpano",
    subMenu: null,
  },
  bootstrap: {
    id: "bootstrap",
    text: "Bootstrap Components",
    path: "#bootstrap",
    icon: "BootstrapFill",
    subMenu: null,
  },
  storybook: {
    id: "storybook",
    text: "Storybook",
    path: "#storybook",
    icon: "CustomStorybook",
    subMenu: null,
  },
  formik: {
    id: "formik",
    text: "Formik",
    path: "#formik",
    icon: "CheckBox",
    subMenu: null,
  },
};

const decodedJwt = authToken();
const dataUsers = getDataUser();
const moduleName: any = [
  CONSTANT.MODULES.PRODUCT,
  CONSTANT.MODULES.INGREDIENT,
  CONSTANT.MODULES.SKU,
  CONSTANT.MODULES.ORDERS,
  CONSTANT.MODULES.USER,
  CONSTANT.MODULES.PROMO_CODE,
  CONSTANT.MODULES.CATEGORY,
  CONSTANT.MODULES.SUB_CATEGORY,
  CONSTANT.MODULES.KEYWORD,
  CONSTANT.MODULES.BENEFIT,
  CONSTANT.MODULES.MARKETING,
  CONSTANT.MODULES.DELIVERY_OPTIONS,
  CONSTANT.MODULES.FAQ,
  CONSTANT.MODULES.DOSAGE_ASSISTANCE,
  CONSTANT.MODULES.HOW_IT_WORK,
  CONSTANT.MODULES.TRAINER,
  CONSTANT.MODULES.CLASS,
  CONSTANT.MODULES.CHAPTER,
  CONSTANT.MODULES.COURSE,
  CONSTANT.MODULES.COURSEFAQ,
  CONSTANT.MODULES.MASTER,
  CONSTANT.MODULES.CHATGPT,
  CONSTANT.MODULES.BOT,
  CONSTANT.MODULES.USER_PROFILE_CATEGORY,
  CONSTANT.MODULES.SUB_METRIC,
  CONSTANT.MODULES.USER_PROFILE_SUB_CATEGORY,
  CONSTANT.MODULES.USER_PROFILE_QUESTION,
  CONSTANT.MODULES.METRICS,
  CONSTANT.MODULES.CRON_JOB,
  CONSTANT.MODULES.MOBILE_SCREEN,
  CONSTANT.MODULES.USER_DETAIL_SCREEN,
  CONSTANT.MODULES.INTERESTS,
  CONSTANT.MODULES.INTERESTS_QUESTIONS,
  CONSTANT.MODULES.LEVEL_ONE_MAPPING,
  CONSTANT.MODULES.GOAL,
  CONSTANT.MODULES.LEVEL_ZERO_MAPPING,
  CONSTANT.MODULES.LAB_TEST,
  CONSTANT.MODULES.DEVICE,
  CONSTANT.MODULES.ASSESSMENT,
  CONSTANT.MODULES.ASSESSMENT_QUESTION,
  CONSTANT.MODULES.RECOMMENDATION_RULE,
  CONSTANT.MODULES.RECIPE,
  CONSTANT.MODULES.RECIPE_BUNDLE,
  CONSTANT.MODULES.NUGGETS,
  CONSTANT.MODULES.NUGGETS_TAG,
  CONSTANT.MODULES.PRIVACY_POLICY,
  CONSTANT.MODULES.TERMS_OF_USE_POLICY,
  CONSTANT.MODULES.MODEL,
  CONSTANT.MODULES.CAREER
];

const modules: any = {};
moduleName.forEach((moduleNames: any) => {
  modules[moduleNames] =
    dataUsers &&
    dataUsers.permissions &&
    dataUsers.permissions.filter(
      (permission: any) => permission.moduleName === moduleNames
    );
});

const modulePermissions: any = {};
moduleName.forEach((type: any) => {
  modulePermissions[type] =
    (modules[type] && modules[type]?.[0]?.roleAndPermission?.CRM?.view) ||
    modules[type]?.[0]?.roleAndPermission?.CONTENT?.view ||
    modules[type]?.[0]?.roleAndPermission?.WAREHOUSE?.view;
});

const userViewDetails = modulePermissions[CONSTANT.MODULES.USER];
const chatGptDetails = modulePermissions[CONSTANT.MODULES.CHATGPT];
const botDetails = modulePermissions[CONSTANT.MODULES.BOT];
const usercategoryDetails =
  modulePermissions[CONSTANT.MODULES.USER_PROFILE_CATEGORY];
const userSubcategoryDetails =
  modulePermissions[CONSTANT.MODULES.USER_PROFILE_SUB_CATEGORY];
const userQuestionDetails =
  modulePermissions[CONSTANT.MODULES.USER_PROFILE_QUESTION];
const cronjobViewDetails = modulePermissions[CONSTANT.MODULES.CRON_JOB];
const metricViewDetails = modulePermissions[CONSTANT.MODULES.METRICS];
const mobileScreenViewDetails =
  modulePermissions[CONSTANT.MODULES.MOBILE_SCREEN];
const userscreenDetails =
  modulePermissions[CONSTANT.MODULES.USER_DETAIL_SCREEN];
const interestDetails = modulePermissions[CONSTANT.MODULES.INTERESTS];
const interestQuestionDetails =
  modulePermissions[CONSTANT.MODULES.INTERESTS_QUESTIONS];
  const levelZeroMappingDetails =
  modulePermissions[CONSTANT.MODULES.LEVEL_ZERO_MAPPING];
const levelOneMappingDetails =
  modulePermissions[CONSTANT.MODULES.LEVEL_ONE_MAPPING];
const goalDetails = modulePermissions[CONSTANT.MODULES.GOAL];
const labTestDetails = modulePermissions[CONSTANT.MODULES.LAB_TEST];
const deviceDetails = modulePermissions[CONSTANT.MODULES.DEVICE];
const assessmentDetails = modulePermissions[CONSTANT.MODULES.ASSESSMENT];
const assessmentQuestionDetails = modulePermissions[CONSTANT.MODULES.ASSESSMENT_QUESTION];
const recommendationRuleDetails = modulePermissions[CONSTANT.MODULES.RECOMMENDATION_RULE];
const productDetails = modulePermissions[CONSTANT.MODULES.PRODUCT];
const recipeDetails = modulePermissions[CONSTANT.MODULES.RECIPE];
const recipeBundleDetails = modulePermissions[CONSTANT.MODULES.RECIPE_BUNDLE];
const nuggetsDetails = modulePermissions[CONSTANT.MODULES.NUGGETS];
const nuggetsTagDetails = modulePermissions[CONSTANT.MODULES.NUGGETS_TAG];
const privacyPolicyDetails = modulePermissions[CONSTANT.MODULES.PRIVACY_POLICY];
const termsOfUsePolicyDetails = modulePermissions[CONSTANT.MODULES.TERMS_OF_USE_POLICY];
const categoyViewDetails = modulePermissions[CONSTANT.MODULES.CATEGORY];
const subCategoryViewDetails = modulePermissions[CONSTANT.MODULES.SUB_CATEGORY];
const modelViewDetails = modulePermissions[CONSTANT.MODULES.MODEL];
const careerDetails = modulePermissions[CONSTANT.MODULES.CAREER];

export const dashboardPagesMenu = {
  dashboard: {
    id: "dashboard",
    text: "Dashboard",
    path: "/",
    icon: "Dashboard",
  },

  ...((decodedJwt &&
    decodedJwt.userType &&
    decodedJwt.userType === CONSTANT.ROLES.ADMIN && {
      Users: {
        id: "User",
        text: "User",
        path: "/users-view",
        icon: "Person",
        subMenu: [
          {
            id: "user",
            text: "User Listing",
            path: ROUTING_CONSTANT.USER_VIEW,
            icon: "Person",
          },
          {
            id: "Assessment",
            text: "Assessment",
            path: ROUTING_CONSTANT.ASSESSMENT_VIEW,
            icon: "Assessment",
          },
          {
            id: "AssessmentQuestion",
            text: "Assessment Question",
            path: ROUTING_CONSTANT.ASSESSMENT_QUESTION_VIEW,
            icon: "HelpOutline",
          },
        ],
      },
    }) ||
    ((userViewDetails === true || assessmentDetails === true || assessmentQuestionDetails === true) && {
        Users: {
        id: "User",
        text: "User",
        path: "/users-view",
        icon: "Person",
        subMenu: [
          userViewDetails === true && {
            id: "user",
            text: "User Listing",
            path: ROUTING_CONSTANT.USER_VIEW,
            icon: "Person",
          },
          assessmentDetails === true && {
            id: "Assessment",
            text: "Assessment",
            path: ROUTING_CONSTANT.ASSESSMENT_VIEW,
            icon: "Assessment",
          },
          assessmentQuestionDetails === true && {
            id: "AssessmentQuestion",
            text: "Assessment Question",
            path: ROUTING_CONSTANT.ASSESSMENT_QUESTION_VIEW,
            icon: "HelpOutline",
          },
        ],
      },
    })),

  ...(decodedJwt &&
    decodedJwt.userType &&
    decodedJwt.userType === CONSTANT.ROLES.ADMIN && {
      roles_permission: {
        id: "roles_permission",
        text: "Roles & Permission",
        path: ROUTING_CONSTANT.ROLES_PERMISSION,
        icon: "AdminPanelSettings",
      },
    }),

  ...((decodedJwt &&
    decodedJwt.userType &&
    decodedJwt.userType === CONSTANT.ROLES.ADMIN && {
      Admin: {
        id: "Admin",
        text: "Admin",
        path: "/admin-view",
        icon: "AssignmentInd",
        subMenu: [
          {
            id: "usercategory",
            text: "User Profile Category",
            path: ROUTING_CONSTANT.USER_PROFILE_CATEGORY_VIEW,
            icon: "Category",
          },
          {
            id: "usersubcategory",
            text: "User Profile Sub Category",
            path: ROUTING_CONSTANT.USER_PROFILE_SUB_CATEGORY_VIEW,
            icon: "List",
          },
          {
            id: "userprofilequestion",
            text: "User Profile Question",
            path: ROUTING_CONSTANT.USER_PROFILE_QUESTION_VIEW,
            icon: "HelpOutline",
          },
          {
            id: "defaultconversation",
            text: "Default Conversation",
            path: ROUTING_CONSTANT.DEFAULT_CONVERSATION,
            icon: "ChatBubble",
          },
        ],
      },
    }) ||
    ((usercategoryDetails === true ||
      userSubcategoryDetails === true ||
      userQuestionDetails === true) && {
      Admin: {
        id: "Admin",
        text: "Admin",
        path: "/admin-view",
        icon: "AssignmentInd",
        subMenu: [
          usercategoryDetails === true && {
            id: "usercategory",
            text: "User Profile Category",
            path: ROUTING_CONSTANT.USER_PROFILE_CATEGORY_VIEW,
            icon: "Category",
          },
          userSubcategoryDetails === true && {
            id: "usersubcategory",
            text: "User Profile Sub Category",
            path: ROUTING_CONSTANT.USER_PROFILE_SUB_CATEGORY_VIEW,
            icon: "List",
          },
          {
            id: "userprofilequestion",
            text: "User Profile Question",
            path: ROUTING_CONSTANT.USER_PROFILE_QUESTION_VIEW,
            icon: "HelpOutline",
          },
          {
            id: "defaultconversation",
            text: "Default Conversation",
            path: ROUTING_CONSTANT.DEFAULT_CONVERSATION,
            icon: "ChatBubble",
          },
        ],
      },
    })),
  ...((decodedJwt &&
    decodedJwt.userType &&
    decodedJwt.userType === CONSTANT.ROLES.ADMIN && {
      Data: {
        id: "data",
        text: "Data",
        path: "/data-view",
        icon: "DataUsage",
        subMenu: [
          {
            id: "sub-metric",
            text: "Metric",
            path: ROUTING_CONSTANT.SUB_METRIC_VIEW,
            icon: "Equalizer",
          },
          {
            id: "level-Zero-Mapping",
            text: "Level Zero Mapping",
            path: ROUTING_CONSTANT.LEVEL_ZERO_MAPPING_VIEW,
            icon: "TrendingUp",
          },
          {
            id: "level-One-Mapping",
            text: "Level One Mapping",
            path: ROUTING_CONSTANT.LEVEL_ONE_MAPPING_VIEW,
            icon: "ScatterPlot",
          },
          {
            id: "cron-Job",
            text: "Cron-Job",
            path: ROUTING_CONSTANT.CRON_JOB_VIEW,
            icon: "AccessTime",
          },
          {
            id: "goal",
            text: "Goal",
            path: ROUTING_CONSTANT.GOAL_VIEW,
            icon: "AdsClick",
          },
          {
            id: "Recommendation-Rule",
            text: "Recommendation Rule",
            path: ROUTING_CONSTANT.RECOMMENDATION_RULE_VIEW,
            icon: "Recommend",
          },
        ],
      },
    }) ||
    ((metricViewDetails === true ||
      cronjobViewDetails === true ||levelZeroMappingDetails === true ||
      levelOneMappingDetails === true ||
      goalDetails === true || recommendationRuleDetails === true) && {
      Data: {
        id: "data",
        text: "Data",
        path: "/data-view",
        icon: "DataUsage",
        subMenu: [
          metricViewDetails === true && {
            id: "sub-metric",
            text: "Metric",
            path: ROUTING_CONSTANT.SUB_METRIC_VIEW,
            icon: "Equalizer",
          },
          levelZeroMappingDetails === true && {
            id: "level-Zero-Mapping",
            text: "Level Zero Mapping",
            path: ROUTING_CONSTANT.LEVEL_ZERO_MAPPING_VIEW,
            icon: "TrendingUp",
          },
          levelOneMappingDetails === true && {
            id: "level-One-Mapping",
            text: "Level One Mapping",
            path: ROUTING_CONSTANT.LEVEL_ONE_MAPPING_VIEW,
            icon: "ScatterPlot",
          },
          cronjobViewDetails === true && {
            id: "cron-Job",
            text: "Cron-Job",
            path: ROUTING_CONSTANT.CRON_JOB_VIEW,
            icon: "AccessTime",
          },
          goalDetails === true && {
            id: "goal",
            text: "Goal",
            path: ROUTING_CONSTANT.GOAL_VIEW,
            icon: "AdsClick",
          },
          recommendationRuleDetails === true && {
            id: "Recommendation-Rule",
            text: "Recommendation Rule",
            path: ROUTING_CONSTANT.RECOMMENDATION_RULE_VIEW,
            icon: "Recommend",
          },
        ],
      },
    })),

  ...((decodedJwt &&
    decodedJwt.userType &&
    decodedJwt.userType === CONSTANT.ROLES.ADMIN && {
      settings: {
        id: "Settings",
        text: "Settings",
        path: "/setting-view",
        icon: "Settings",
        subMenu: [
          {
            id: "mobileIntro",
            text: "Mobile Screen Intro",
            path: ROUTING_CONSTANT.MOBILE_SCREEN_VIEW,
            icon: "MobileScreenShare",
          },
          {
            id: "userScreenDetail",
            text: "User Screen Detail",
            path: ROUTING_CONSTANT.USER_DETAIL_SCREEN_VIEW,
            icon: "Details",
          },
        ],
      },
    }) ||
    ((mobileScreenViewDetails === true || userscreenDetails === true) && {
      settings: {
        id: "Settings",
        text: "Settings",
        path: "/setting-view",
        icon: "Settings",
        subMenu: [
          mobileScreenViewDetails === true && {
            id: "mobileIntro",
            text: "Mobile Screen Intro",
            path: ROUTING_CONSTANT.MOBILE_SCREEN_VIEW,
            icon: "MobileScreenShare",
          },
          userscreenDetails === true && {
            id: "userScreenDetail",
            text: "User Screen Detail",
            path: ROUTING_CONSTANT.USER_DETAIL_SCREEN_VIEW,
            icon: "Details",
          },
        ],
      },
    })),

  ...((decodedJwt &&
    decodedJwt.userType &&
    decodedJwt.userType === CONSTANT.ROLES.ADMIN && {
      interests: {
        id: "Interests",
        text: "Interests",
        path: "/interest-view",
        icon: "Insights",
        subMenu: [
          {
            id: "interest",
            text: "Interests",
            path: ROUTING_CONSTANT.INTERESTS_VIEW,
            icon: "Insights",
          },
          {
            id: "InterestQuestions",
            text: "Interest Questions",
            path: ROUTING_CONSTANT.INTERESTS_QUESTIONS_VIEW,
            icon: "HelpCenter",
          },
        ],
      },
    }) ||
    ((interestDetails === true || interestQuestionDetails === true) && {
      interests: {
        id: "Interests",
        text: "Interests",
        path: "/interest-view",
        icon: "Insights",
        subMenu: [
          interestDetails === true && {
            id: "interest",
            text: "Interests",
            path: ROUTING_CONSTANT.INTERESTS_VIEW,
            icon: "Insights",
          },
          interestQuestionDetails === true && {
            id: "InterestQuestions",
            text: "Interest Questions",
            path: ROUTING_CONSTANT.INTERESTS_QUESTIONS_VIEW,
            icon: "HelpCenter",
          },
        ],
      },
    })),

        ...((decodedJwt &&
            decodedJwt.userType &&
            decodedJwt.userType === CONSTANT.ROLES.ADMIN && {
              Store: {
                id: "Store",
                text: "Store",
                path: "/store-view",
                icon: "Store",
                subMenu: [
                  {
                    id: "device",
                    text: "Device",
                    path: ROUTING_CONSTANT.DEVICE_VIEW,
                    icon: "Devices",
                  },
                  {
                    id: "deviceCategory",
                    text: "Device Category",
                    path: ROUTING_CONSTANT.CATEGORY_VIEW,
                    icon: "Category",
                  },
                  {
                    id: "deviceSubCategory",
                    text: "Device Sub Category",
                    path: ROUTING_CONSTANT.SUB_CATEGORY_VIEW,
                    icon: "List",
                  },
                  {
                    id: "deviceModel",
                    text: "Device Model",
                    path: ROUTING_CONSTANT.MODEL_VIEW,
                    icon: "ModelTraining",
                  },
                  {
                    id: "product",
                    text: "Product",
                    path: ROUTING_CONSTANT.PRODUCT_VIEW,
                    icon: "ProductionQuantityLimits",
                  },
                  {
                    id: "recipe",
                    text: "Recipe",
                    path: ROUTING_CONSTANT.RECIPE_VIEW,
                    icon: "Countertops",
                  },
                  {
                    id: "recipeBundle",
                    text: "Recipe Bundle",
                    path: ROUTING_CONSTANT.RECIPE_BUNDLE_VIEW,
                    icon: "BreakfastDining",
                  },
                  {
                    id: "labTest",
                    text: "Lab Test",
                    path: ROUTING_CONSTANT.LAB_TEST_VIEW,
                    icon: "Biotech",
                  },
                ],
              },
            }) ||
            ((deviceDetails === true ||
              productDetails === true ||
              recipeDetails === true || recipeBundleDetails === true || categoyViewDetails === true || subCategoryViewDetails === true || modelViewDetails === true) && {
              Store: {
                id: "Store",
                text: "Store",
                path: "/store-view",
                icon: "Store",
                subMenu: [
                  deviceDetails === true && {
                    id: "device",
                    text: "Device",
                    path: ROUTING_CONSTANT.DEVICE_VIEW,
                    icon: "Devices",
                  },
                  categoyViewDetails === true && {
                    id: "deviceCategory",
                    text: "Device Category",
                    path: ROUTING_CONSTANT.CATEGORY_VIEW,
                    icon: "Category",
                  },
                  subCategoryViewDetails === true && {
                    id: "deviceSubCategory",
                    text: "Device Sub Category",
                    path: ROUTING_CONSTANT.SUB_CATEGORY_VIEW,
                    icon: "List",
                  },
                  modelViewDetails === true && {
                    id: "deviceModel",
                    text: "Device Model",
                    path: ROUTING_CONSTANT.MODEL_VIEW,
                    icon: "ModelTraining",
                  },
                  productDetails === true && {
                    id: "product",
                    text: "Product",
                    path: ROUTING_CONSTANT.PRODUCT_VIEW,
                    icon: "ProductionQuantityLimits",
                  },
                  recipeDetails === true && {
                    id: "recipe",
                    text: "Recipe",
                    path: ROUTING_CONSTANT.RECIPE_VIEW,
                    icon: "Countertops",
                  },
                  recipeBundleDetails === true && {
                    id: "recipeBundle",
                    text: "Recipe Bundle",
                    path: ROUTING_CONSTANT.RECIPE_BUNDLE_VIEW,
                    icon: "BreakfastDining",
                  },
                  labTestDetails === true && {
                    id: "labTest",
                    text: "Lab Test",
                    path: ROUTING_CONSTANT.LAB_TEST_VIEW,
                    icon: "Biotech",
                  },
                ],
              },
            })),

            ...((decodedJwt &&
                decodedJwt.userType &&
                decodedJwt.userType === CONSTANT.ROLES.ADMIN && {
                  nuggets: {
                    id: "Nuggets",
                    text: "Nuggets",
                    path: "/nuggets-view",
                    icon: "Style",
                    subMenu: [
                      {
                        id: "nuggetsTag",
                        text: "Nugget Tag",
                        path: ROUTING_CONSTANT.NUGGETS_TAG_VIEW,
                        icon: "LocalOffer",
                      },
                      {
                        id: "nuggets",
                        text: "Nugget",
                        path: ROUTING_CONSTANT.NUGGETS_VIEW,
                        icon: "Style",
                      },
                    ],
                  },
                }) ||
                ((nuggetsTagDetails === true || nuggetsDetails === true) && {
                  nuggets: {
                    id: "Nuggets",
                    text: "Nuggets",
                    path: "/nuggets-view",
                    icon: "Style",
                    subMenu: [
                        nuggetsTagDetails === true && {
                        id: "nuggetsTag",
                        text: "Nugget Tag",
                        path: ROUTING_CONSTANT.NUGGETS_TAG_VIEW,
                        icon: "LocalOffer",
                      },
                      nuggetsDetails === true && {
                        id: "nuggets",
                        text: "Nugget",
                        path: ROUTING_CONSTANT.NUGGETS_VIEW,
                        icon: "Style",
                      },
                    ],
                  },
                })),

                ...((decodedJwt &&
                    decodedJwt.userType &&
                    decodedJwt.userType === CONSTANT.ROLES.ADMIN && {
                      policy: {
                        id: "Policy",
                        text: "Policy",
                        path: "/policy-view",
                        icon: "PlaylistAddCheck",
                        subMenu: [
                          {
                            id: "privacyPolicy",
                            text: "Privacy Policy",
                            path: ROUTING_CONSTANT.PRIVACY_POLICY_VIEW,
                            icon: "Policy",
                          },
                          {
                            id: "termsOfUsePolicy",
                            text: "Terms Of Use Policy",
                            path: ROUTING_CONSTANT.TERMS_OF_USE_POLICY_VIEW,
                            icon: "Gavel",
                          },
                        ],
                      },
                    }) ||
                    ((privacyPolicyDetails === true || termsOfUsePolicyDetails === true) && {
                        policy: {
                        id: "Policy",
                        text: "Policy",
                        path: "/policy-view",
                        icon: "PlaylistAddCheck",
                        subMenu: [
                            privacyPolicyDetails === true && {
                            id: "privacyPolicy",
                            text: "Privacy Policy",
                            path: ROUTING_CONSTANT.PRIVACY_POLICY_VIEW,
                            icon: "Policy",
                          },
                          termsOfUsePolicyDetails === true && {
                            id: "termsOfUsePolicy",
                            text: "Terms Of Use Policy",
                            path: ROUTING_CONSTANT.TERMS_OF_USE_POLICY_VIEW,
                            icon: "Gavel",
                          },
                        ],
                      },
                    })),

                ...((decodedJwt &&
                    decodedJwt.userType &&
                    decodedJwt.userType === CONSTANT.ROLES.ADMIN && {
                    bot: {
                        id: "bot",
                        text: "Bot",
                        path: ROUTING_CONSTANT.BOT_VIEW,
                        icon: "SmartToy",
                    },
                    }) ||
                    (botDetails === true && {
                    bot: {
                        id: "bot",
                        text: "Bot",
                        path: ROUTING_CONSTANT.BOT_VIEW,
                        icon: "SmartToy",
                    },
                })),

                ...((decodedJwt &&
                    decodedJwt.userType &&
                    decodedJwt.userType === CONSTANT.ROLES.ADMIN && {
                    career: {
                        id: "career",
                        text: "Career",
                        path: ROUTING_CONSTANT.CAREER_VIEW,
                        icon: "SmartToy",
                    },
                    }) ||
                    (careerDetails === true && {
                        career: {
                        id: "career",
                        text: "Career",
                        path: ROUTING_CONSTANT.CAREER_VIEW,
                        icon: "SmartToy",
                    },
                    })),

                ...((decodedJwt &&
                    decodedJwt.userType &&
                    decodedJwt.userType === CONSTANT.ROLES.ADMIN && {
                    chatGpt: {
                        id: "chatGpt",
                        text: "Chat GPT",
                        path: ROUTING_CONSTANT.CHATGPT_VIEW,
                        icon: "Chat",
                    },
                    }) ||
                    (chatGptDetails === true && {
                    chatGpt: {
                        id: "chatGpt",
                        text: "Chat GPT",
                        path: ROUTING_CONSTANT.CHATGPT_VIEW,
                        icon: "Chat",
                    },
                })),
};
