import React, {
    FC,
    memo,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import { TIcons } from "../../type/icons-type";
import useDarkMode from "../../hooks/useDarkMode";
import { useWindowSize } from "react-use";
import ThemeContext from "../../contexts/themeContext";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import Icon from "../../components/icon/Icon";
import { NavHashLink } from "react-router-hash-link";
import { Manager, Popper, Reference } from "react-popper";
import Collapse from "../../components/bootstrap/Collapse";
import PropTypes from "prop-types";
import { List } from "./Navigation";
// @ts-ignore
import useEventOutside from "@omtanke/react-use-event-outside";
import { ROUTING_CONSTANT } from "../../core/constant";

interface IItemProps {
    children?: React.ReactNode;
    to?: string;
    title?: string;
    icon?: TIcons;
    id?: string;
    parentId?: string;
    rootId: string;
    isHorizontal?: boolean;
    notification?: boolean | string;
    isMore?: boolean;
    hide?: boolean;
    activeItem?: string;
    setActiveItem?(...args: unknown[]): unknown;
}
const Item: FC<IItemProps> = ({
    children,
    to,
    title,
    icon,
    id,
    parentId,
    rootId,
    isHorizontal,
    notification,
    isMore,
    hide,
    ...props
}) => {
    const { darkModeStatus } = useDarkMode();
    const { width } = useWindowSize();
    const { setAsideStatus, setLeftMenuStatus, setRightMenuStatus } =
        useContext(ThemeContext);

    // eslint-disable-next-line react/prop-types
    const ACTIVE = props.activeItem === id;

    const handleClick = () => {
        if (typeof props.setActiveItem !== "undefined") {
            // eslint-disable-next-line react/prop-types, @typescript-eslint/no-unused-expressions
            ACTIVE ? props.setActiveItem(null) : props.setActiveItem(id);
        }
    };

    const linkHandleClick = () => {
        // For Mobile Design
        if (width < Number(process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE))
            setAsideStatus(false);
        setLeftMenuStatus(false);
        setRightMenuStatus(false);
    };

    const ANCHOR_LINK_PATTERN = /^#/i;
    const location = useLocation();

    const isToPathActive =
        typeof to === "string" && to !== "/" && location.pathname.includes(to);

    const pathsToExclude = [
        "/",
        ROUTING_CONSTANT.PRODUCT_VIEW,
        ROUTING_CONSTANT.ROLES_PERMISSION,
        ROUTING_CONSTANT.ORDERS_VIEW,
        ROUTING_CONSTANT.USER_VIEW,
        ROUTING_CONSTANT.PROMO_CODE_VIEW,
        ROUTING_CONSTANT.CATEGORY_VIEW,
        ROUTING_CONSTANT.SUB_CATEGORY_VIEW,
        ROUTING_CONSTANT.SKU_VIEW,
        ROUTING_CONSTANT.INGREDIENT_VIEW,
        ROUTING_CONSTANT.MARKETING_VIEW,
        ROUTING_CONSTANT.DOSAGE_ASSISTANCE_VIEW,
        ROUTING_CONSTANT.BENEFIT_VIEW,
        ROUTING_CONSTANT.FAQ_VIEW,
        ROUTING_CONSTANT.KEYWORD_VIEW,
        ROUTING_CONSTANT.HOW_IT_WORK_VIEW,
        ROUTING_CONSTANT.DELIVERY_OPTIONS_VIEW,
        ROUTING_CONSTANT.TRAINER_VIEW,
        ROUTING_CONSTANT.CLASS_VIEW,
        ROUTING_CONSTANT.MASTER_VIEW,
        ROUTING_CONSTANT.CHAPTER_VIEW,
        ROUTING_CONSTANT.CHATGPT_VIEW,
        ROUTING_CONSTANT.COURSE_VIEW,
        ROUTING_CONSTANT.COURSE_FAQ_VIEW,
        ROUTING_CONSTANT.SELF_TRAIN_VIEW,
        ROUTING_CONSTANT.SHOP_VIEW,
        ROUTING_CONSTANT.BOT_VIEW,
        ROUTING_CONSTANT.USER_PROFILE_CATEGORY_VIEW,
        ROUTING_CONSTANT.SUB_METRIC_VIEW,
        ROUTING_CONSTANT.USER_PROFILE_SUB_CATEGORY_VIEW,
        ROUTING_CONSTANT.USER_PROFILE_QUESTION_VIEW,
        ROUTING_CONSTANT.ADMIN_VIEW,
        ROUTING_CONSTANT.SETTING_VIEW,
        ROUTING_CONSTANT.INTEREST_VIEW,
        ROUTING_CONSTANT.SUB_METRIC_VIEW,
        ROUTING_CONSTANT.CRON_JOB_VIEW,
        ROUTING_CONSTANT.MOBILE_SCREEN_VIEW,
        ROUTING_CONSTANT.USER_DETAIL_SCREEN_VIEW,
        ROUTING_CONSTANT.INTERESTS_VIEW,
        ROUTING_CONSTANT.INTERESTS_QUESTIONS_VIEW,
        ROUTING_CONSTANT.LEVEL_ONE_MAPPING_VIEW,
        ROUTING_CONSTANT.GOAL_VIEW,
        ROUTING_CONSTANT.LEVEL_ZERO_MAPPING_VIEW,
        ROUTING_CONSTANT.LAB_TEST_VIEW,
        ROUTING_CONSTANT.DEVICE_VIEW,
        ROUTING_CONSTANT.ASSESSMENT_VIEW,
        ROUTING_CONSTANT.ASSESSMENT_QUESTION_VIEW,
        ROUTING_CONSTANT.RECOMMENDATION_RULE_VIEW,
        ROUTING_CONSTANT.RECIPE_VIEW,
        ROUTING_CONSTANT.USERS_VIEW,
        ROUTING_CONSTANT.STORE_VIEW,
        ROUTING_CONSTANT.RECIPE_BUNDLE_VIEW,
        ROUTING_CONSTANT.NUGGETS_MENU_VIEW,
        ROUTING_CONSTANT.NUGGETS_TAG_VIEW,
        ROUTING_CONSTANT.NUGGETS_VIEW,
        ROUTING_CONSTANT.POLICY_VIEW,
        ROUTING_CONSTANT.PRIVACY_POLICY_VIEW,
        ROUTING_CONSTANT.TERMS_OF_USE_POLICY_VIEW,
        ROUTING_CONSTANT.DATA_VIEW,
        ROUTING_CONSTANT.CAREER_VIEW
    ];

    const userPathExcludes = pathsToExclude.filter(
        (path) => path !== ROUTING_CONSTANT.USER_VIEW
    );
    const careerPathExcludes = pathsToExclude.filter(
        (path) => path !== ROUTING_CONSTANT.CAREER_VIEW
    );
    const assessmentExcludes = pathsToExclude.filter(
        (path) => path !== ROUTING_CONSTANT.ASSESSMENT_VIEW
    );
    const nuggetsTagExcludes = pathsToExclude.filter(
        (path) => path !== ROUTING_CONSTANT.NUGGETS_TAG_VIEW
    );
    const nuggetsExcludes = pathsToExclude.filter(
        (path) => path !== ROUTING_CONSTANT.NUGGETS_VIEW
    );
    const assessmentQuestionExcludes = pathsToExclude.filter(
        (path) => path !== ROUTING_CONSTANT.ASSESSMENT_QUESTION_VIEW
    );
    const recommendationRuleExcludes = pathsToExclude.filter(
        (path) => path !== ROUTING_CONSTANT.RECOMMENDATION_RULE_VIEW
    );
    const recipeExcludes = pathsToExclude.filter(
        (path) => path !== ROUTING_CONSTANT.RECIPE_VIEW
    );
    const recipeBundleExcludes = pathsToExclude.filter(
        (path) => path !== ROUTING_CONSTANT.RECIPE_BUNDLE_VIEW
    );
    const devicePathExcludes = pathsToExclude.filter(
        (path) => path !== ROUTING_CONSTANT.DEVICE_VIEW
    );
    const interestExcludes = pathsToExclude.filter(
        (path) => path !== ROUTING_CONSTANT.INTERESTS_VIEW
    );
    const labTestExcludes = pathsToExclude.filter(
        (path) => path !== ROUTING_CONSTANT.LAB_TEST_VIEW
    );
    const levelOneMappingExcludes = pathsToExclude.filter(
        (path) => path !== ROUTING_CONSTANT.LEVEL_ONE_MAPPING_VIEW
    );

    const levelZeroMappingExcludes = pathsToExclude.filter(
        (path) => path !== ROUTING_CONSTANT.LEVEL_ZERO_MAPPING_VIEW
    );
    
    const interestQuestionExcludes = pathsToExclude.filter(
        (path) => path !== ROUTING_CONSTANT.INTERESTS_QUESTIONS_VIEW
    );
    const promoCodePathExcludes = pathsToExclude.filter(
        (path) => path !== ROUTING_CONSTANT.PROMO_CODE_VIEW
    );
    const categoryPathExcludes = pathsToExclude.filter(
        (path) => path !== ROUTING_CONSTANT.CATEGORY_VIEW
    );
    const subCategoryPathExcludes = pathsToExclude.filter(
        (path) => path !== ROUTING_CONSTANT.SUB_CATEGORY_VIEW
    );
    const productPathExcludes = pathsToExclude.filter(
        (path) => path !== ROUTING_CONSTANT.PRODUCT_VIEW
    );
    const skuPathExcludes = pathsToExclude.filter(
        (path) => path !== ROUTING_CONSTANT.SKU_VIEW
    );
    const ingredientPathExcludes = pathsToExclude.filter(
        (path) => path !== ROUTING_CONSTANT.INGREDIENT_VIEW
    );
    const marketingPathExcludes = pathsToExclude.filter(
        (path) => path !== ROUTING_CONSTANT.MARKETING_VIEW
    );
    const dosagePathExcludes = pathsToExclude.filter(
        (path) => path !== ROUTING_CONSTANT.DOSAGE_ASSISTANCE_VIEW
    );
    const benefitPathExcludes = pathsToExclude.filter(
        (path) => path !== ROUTING_CONSTANT.BENEFIT_VIEW
    );
    const faqPathExcludes = pathsToExclude.filter(
        (path) => path !== ROUTING_CONSTANT.FAQ_VIEW
    );
    const keywordPathExcludes = pathsToExclude.filter(
        (path) => path !== ROUTING_CONSTANT.KEYWORD_VIEW
    );
    const howItPathExcludes = pathsToExclude.filter(
        (path) => path !== ROUTING_CONSTANT.HOW_IT_WORK_VIEW
    );
    const deliveryPathExcludes = pathsToExclude.filter(
        (path) => path !== ROUTING_CONSTANT.DELIVERY_OPTIONS_VIEW
    );
    const questionPathExcludes = pathsToExclude.filter(
        (path) => path !== ROUTING_CONSTANT.CHATGPT_VIEW
    );
    const trainerPathExcludes = pathsToExclude.filter(
        (path) => path !== ROUTING_CONSTANT.TRAINER_VIEW
    );
    const classPathExcludes = pathsToExclude.filter(
        (path) => path !== ROUTING_CONSTANT.CLASS_VIEW
    );
    const coursePathExcludes = pathsToExclude.filter(
        (path) => path !== ROUTING_CONSTANT.COURSE_VIEW
    );
    const coursefaqsPathExcludes = pathsToExclude.filter(
        (path) => path !== ROUTING_CONSTANT.COURSE_FAQ_VIEW
    );
    const masterPathExcludes = pathsToExclude.filter(
        (path) => path !== ROUTING_CONSTANT.MASTER_VIEW
    );
    const chapterPathExcludes = pathsToExclude.filter(
        (path) => path !== ROUTING_CONSTANT.CHAPTER_VIEW
    );
    const botPathExcludes = pathsToExclude.filter(
        (path) => path !== ROUTING_CONSTANT.BOT_VIEW
    );
    const userProfileCategoryPathExcludes = pathsToExclude.filter(
        (path) => path !== ROUTING_CONSTANT.USER_PROFILE_CATEGORY_VIEW
    );
    const metricPathExcludes = pathsToExclude.filter(
        (path) => path !== ROUTING_CONSTANT.SUB_METRIC_VIEW
    );
    const userProfileSubCategoryPathExcludes = pathsToExclude.filter(
        (path) => path !== ROUTING_CONSTANT.USER_PROFILE_SUB_CATEGORY_VIEW
    );
    const userProfileQuestionPathExcludes = pathsToExclude.filter(
        (path) => path !== ROUTING_CONSTANT.USER_PROFILE_QUESTION_VIEW
    );
    const cronJobPathExcludes = pathsToExclude.filter(
        (path) => path !== ROUTING_CONSTANT.CRON_JOB_VIEW
    );
    const goalExcludes = pathsToExclude.filter(
        (path) => path !== ROUTING_CONSTANT.GOAL_VIEW
    );
    const mobileScreenPathExcludes = pathsToExclude.filter(
        (path) => path !== ROUTING_CONSTANT.MOBILE_SCREEN_VIEW
    );
    const userDetailScreenPathExcludes = pathsToExclude.filter(
        (path) => path !== ROUTING_CONSTANT.USER_DETAIL_SCREEN_VIEW
    );
    const privacyPolicyExcludes = pathsToExclude.filter(
        (path) => path !== ROUTING_CONSTANT.PRIVACY_POLICY_VIEW
    );
    const termsOfUsePolicyExcludes = pathsToExclude.filter(
        (path) => path !== ROUTING_CONSTANT.TERMS_OF_USE_POLICY_VIEW
    );

    const isUserPathActive =
        typeof to === "string" &&
        !userPathExcludes.includes(to) &&
        (
            location.pathname === ROUTING_CONSTANT.USER||
            location.pathname.match(new RegExp(`^${ROUTING_CONSTANT.USER}/[A-Za-z0-9-]+$`))
        );
    const isCareerPathActive =
        typeof to === "string" &&
        !careerPathExcludes.includes(to) &&
        (
            location.pathname === ROUTING_CONSTANT.CAREER||
            location.pathname.match(new RegExp(`^${ROUTING_CONSTANT.CAREER}/[A-Za-z0-9-]+$`))
        );
    const isAssessmentPathActive =
        typeof to === "string" &&
        !assessmentExcludes.includes(to) &&
        (
            location.pathname === ROUTING_CONSTANT.ASSESSMENT||
            location.pathname.match(new RegExp(`^${ROUTING_CONSTANT.ASSESSMENT}/[A-Za-z0-9-]+$`))
        );
    const isNuggetsTagPathActive =
        typeof to === "string" &&
        !nuggetsTagExcludes.includes(to) &&
        (
            location.pathname === ROUTING_CONSTANT.NUGGETS_TAG||
            location.pathname.match(new RegExp(`^${ROUTING_CONSTANT.NUGGETS_TAG}/[A-Za-z0-9-]+$`))
        );
    const isNuggetsPathActive =
        typeof to === "string" &&
        !nuggetsExcludes.includes(to) &&
        (
            location.pathname === ROUTING_CONSTANT.NUGGETS||
            location.pathname.match(new RegExp(`^${ROUTING_CONSTANT.NUGGETS}/[A-Za-z0-9-]+$`))
        );
    const isAssessmentQuestionPathActive =
        typeof to === "string" &&
        !assessmentQuestionExcludes.includes(to) &&
        (
            location.pathname === ROUTING_CONSTANT.ASSESSMENT_QUESTION||
            location.pathname.match(new RegExp(`^${ROUTING_CONSTANT.ASSESSMENT_QUESTION}/[A-Za-z0-9-]+$`))
        );
    const isRecommendationRulePathActive =
        typeof to === "string" &&
        !recommendationRuleExcludes.includes(to) &&
        (
            location.pathname === ROUTING_CONSTANT.RECOMMENDATION_RULE||
            location.pathname.match(new RegExp(`^${ROUTING_CONSTANT.RECOMMENDATION_RULE}/[A-Za-z0-9-]+$`))
        );
    const isRecipePathActive =
        typeof to === "string" &&
        !recipeExcludes.includes(to) &&
        (
            location.pathname === ROUTING_CONSTANT.RECIPE||
            location.pathname.match(new RegExp(`^${ROUTING_CONSTANT.RECIPE}/[A-Za-z0-9-]+$`))
        );
    const isRecipeBundlePathActive =
        typeof to === "string" &&
        !recipeBundleExcludes.includes(to) &&
        (
            location.pathname === ROUTING_CONSTANT.RECIPE_BUNDLE||
            location.pathname.match(new RegExp(`^${ROUTING_CONSTANT.RECIPE_BUNDLE}/[A-Za-z0-9-]+$`))
        );
    const isDevicePathActive =
        typeof to === "string" &&
        !devicePathExcludes.includes(to) &&
        (
            location.pathname === ROUTING_CONSTANT.DEVICE||
            location.pathname.match(new RegExp(`^${ROUTING_CONSTANT.DEVICE}/[A-Za-z0-9-]+$`))
        );
    const isMobilePathActive =
        typeof to === "string" &&
        !mobileScreenPathExcludes.includes(to) &&
        (
            location.pathname === ROUTING_CONSTANT.MOBILE_SCREEN||
            location.pathname.match(new RegExp(`^${ROUTING_CONSTANT.MOBILE_SCREEN}/[A-Za-z0-9-]+$`))
        );
    const isUserDetailPathActive =
        typeof to === "string" &&
        !userDetailScreenPathExcludes.includes(to) &&
        (
            location.pathname === ROUTING_CONSTANT.USER_DETAIL_SCREEN||
            location.pathname.match(new RegExp(`^${ROUTING_CONSTANT.USER_DETAIL_SCREEN}/[A-Za-z0-9-]+$`))
        );
    const isInterestPathActive =
        typeof to === "string" &&
        !interestExcludes.includes(to) &&
        (
            location.pathname === ROUTING_CONSTANT.INTERESTS||
            location.pathname.match(new RegExp(`^${ROUTING_CONSTANT.INTERESTS}/[A-Za-z0-9-]+$`))
        );
    const islabTestPathActive =
        typeof to === "string" &&
        !labTestExcludes.includes(to) &&
        (
            location.pathname === ROUTING_CONSTANT.LAB_TEST||
            location.pathname.match(new RegExp(`^${ROUTING_CONSTANT.LAB_TEST}/[A-Za-z0-9-]+$`))
        );
    const isLevelOneMappingPathActive =
        typeof to === "string" &&
        !levelOneMappingExcludes.includes(to) &&
        (
            location.pathname === ROUTING_CONSTANT.LEVEL_ONE_MAPPING||
            location.pathname.match(new RegExp(`^${ROUTING_CONSTANT.LEVEL_ONE_MAPPING}/[A-Za-z0-9-]+$`))
        );

    const isLevelZeroMappingPathActive =
        typeof to === "string" &&
        !levelZeroMappingExcludes.includes(to) &&
        (
            location.pathname === ROUTING_CONSTANT.LEVEL_ZERO_MAPPING||
            location.pathname.match(new RegExp(`^${ROUTING_CONSTANT.LEVEL_ZERO_MAPPING}/[A-Za-z0-9-]+$`))
        );
   
    const isInterestQuestionPathActive =
        typeof to === "string" &&
        !interestQuestionExcludes.includes(to) &&
        (
            location.pathname === ROUTING_CONSTANT.INTERESTS_QUESTIONS||
            location.pathname.match(new RegExp(`^${ROUTING_CONSTANT.INTERESTS_QUESTIONS}/[A-Za-z0-9-]+$`))
        );
    const isUserHardwareSpecificPathExcludes =
        typeof to === "string" &&
        !userPathExcludes.includes(to) &&
        (
            location.pathname === ROUTING_CONSTANT.USER_HARDWARE_SPECIFIC||
            location.pathname.match(new RegExp(`^${ROUTING_CONSTANT.USER_HARDWARE_SPECIFIC}/[A-Za-z0-9-]+$`))
        );
    const isUserMetricSpecificPathExcludes =
        typeof to === "string" &&
        !userPathExcludes.includes(to) &&
        (
            location.pathname === ROUTING_CONSTANT.USER_METRIC_SPECIFIC||
            location.pathname.match(new RegExp(`^${ROUTING_CONSTANT.USER_METRIC_SPECIFIC}/[A-Za-z0-9-]+$`))
        );
    const isUserMedicalSpecificPathExcludes =
        typeof to === "string" &&
        !userPathExcludes.includes(to) &&
        (
            location.pathname === ROUTING_CONSTANT.USER_MEDICAL_SPECIFIC||
            location.pathname.match(new RegExp(`^${ROUTING_CONSTANT.USER_MEDICAL_SPECIFIC}/[A-Za-z0-9-]+$`))
        );
    const isPromoCodePathActive =
        typeof to === "string" &&
        !promoCodePathExcludes.includes(to) &&
        (
            location.pathname === ROUTING_CONSTANT.PROMO_CODE||
            location.pathname.match(new RegExp(`^${ROUTING_CONSTANT.PROMO_CODE}/[A-Za-z0-9-]+$`))
        );
    const isBotPathActive =
        typeof to === "string" &&
        !botPathExcludes.includes(to) &&
        (
            location.pathname === ROUTING_CONSTANT.BOT||
            location.pathname.match(new RegExp(`^${ROUTING_CONSTANT.BOT}/[A-Za-z0-9-]+$`))
        );
    const isCategoryPathActive =
        typeof to === "string" &&
        !categoryPathExcludes.includes(to) &&
        (
            location.pathname === ROUTING_CONSTANT.CATEGORY||
            location.pathname.match(new RegExp(`^${ROUTING_CONSTANT.CATEGORY}/[A-Za-z0-9-]+$`))
        );
    const isSubCategoryPathActive =
        typeof to === "string" &&
        !subCategoryPathExcludes.includes(to) &&
        (
            location.pathname === ROUTING_CONSTANT.SUB_CATEGORY||
            location.pathname.match(new RegExp(`^${ROUTING_CONSTANT.SUB_CATEGORY}/[A-Za-z0-9-]+$`))
        );
    const isProductPathActive =
        typeof to === "string" &&
        !productPathExcludes.includes(to) &&
        (
            location.pathname === ROUTING_CONSTANT.PRODUCT||
            location.pathname.match(new RegExp(`^${ROUTING_CONSTANT.PRODUCT}/[A-Za-z0-9-]+$`))
        );
    const isSkuPathActive =
        typeof to === "string" &&
        !skuPathExcludes.includes(to) &&
        (
            location.pathname === ROUTING_CONSTANT.SKU||
            location.pathname.match(new RegExp(`^${ROUTING_CONSTANT.SKU}/[A-Za-z0-9-]+$`))
        );
    const isIngredientPathActive =
        typeof to === "string" &&
        !ingredientPathExcludes.includes(to) &&
        (
            location.pathname === ROUTING_CONSTANT.INGREDIENT||
            location.pathname.match(new RegExp(`^${ROUTING_CONSTANT.INGREDIENT}/[A-Za-z0-9-]+$`))
        );
    const isMarketingPathActive =
        typeof to === "string" &&
        !marketingPathExcludes.includes(to) &&
        (
            location.pathname === ROUTING_CONSTANT.MARKETING||
            location.pathname.match(new RegExp(`^${ROUTING_CONSTANT.MARKETING}/[A-Za-z0-9-]+$`))
        );
    const isDosagePathActive =
        typeof to === "string" &&
        !dosagePathExcludes.includes(to) &&
        (
            location.pathname === ROUTING_CONSTANT.DOSAGE_ASSISTANCE||
            location.pathname.match(new RegExp(`^${ROUTING_CONSTANT.DOSAGE_ASSISTANCE}/[A-Za-z0-9-]+$`))
        );
    const isBenefitPathActive =
        typeof to === "string" &&
        !benefitPathExcludes.includes(to) &&
        (
            location.pathname === ROUTING_CONSTANT.BENEFIT||
            location.pathname.match(new RegExp(`^${ROUTING_CONSTANT.BENEFIT}/[A-Za-z0-9-]+$`))
        );
    const isFaqPathActive =
        typeof to === "string" &&
        !faqPathExcludes.includes(to) &&
        (
            location.pathname === ROUTING_CONSTANT.FAQ||
            location.pathname.match(new RegExp(`^${ROUTING_CONSTANT.FAQ}/[A-Za-z0-9-]+$`))
        );
    const isKeywordPathActive =
        typeof to === "string" &&
        !keywordPathExcludes.includes(to) &&
        (
            location.pathname === ROUTING_CONSTANT.KEYWORD||
            location.pathname.match(new RegExp(`^${ROUTING_CONSTANT.KEYWORD}/[A-Za-z0-9-]+$`))
        );
    const isHowItPathActive =
        typeof to === "string" &&
        !howItPathExcludes.includes(to) &&
        (
            location.pathname === ROUTING_CONSTANT.HOW_IT_WORK||
            location.pathname.match(new RegExp(`^${ROUTING_CONSTANT.HOW_IT_WORK}/[A-Za-z0-9-]+$`))
        );
    const isDeliveryPathActive =
        typeof to === "string" &&
        !deliveryPathExcludes.includes(to) &&
        (
            location.pathname === ROUTING_CONSTANT.DELIVERY_OPTIONS||
            location.pathname.match(new RegExp(`^${ROUTING_CONSTANT.DELIVERY_OPTIONS}/[A-Za-z0-9-]+$`))
        );
    const isTrainerPathActive =
        typeof to === "string" &&
        !trainerPathExcludes.includes(to) &&
        (
            location.pathname === ROUTING_CONSTANT.TRAINER||
            location.pathname.match(new RegExp(`^${ROUTING_CONSTANT.TRAINER}/[A-Za-z0-9-]+$`))
        );
    const isClassPathActive =
        typeof to === "string" &&
        !classPathExcludes.includes(to) &&
        (
            location.pathname === ROUTING_CONSTANT.CLASS||
            location.pathname.match(new RegExp(`^${ROUTING_CONSTANT.CLASS}/[A-Za-z0-9-]+$`))
        );
    const isCoursePathActive =
        typeof to === "string" &&
        !coursePathExcludes.includes(to) &&
        (
            location.pathname === ROUTING_CONSTANT.COURSE||
            location.pathname.match(new RegExp(`^${ROUTING_CONSTANT.COURSE}/[A-Za-z0-9-]+$`))
        );
    const isCourseFaqsPathActive =
        typeof to === "string" &&
        !coursefaqsPathExcludes.includes(to) &&
        (
            location.pathname === ROUTING_CONSTANT.COURSE_FAQ||
            location.pathname.match(new RegExp(`^${ROUTING_CONSTANT.COURSE_FAQ}/[A-Za-z0-9-]+$`))
        );
    const isMasterPathActive =
        typeof to === "string" &&
        !masterPathExcludes.includes(to) &&
        (
            location.pathname === ROUTING_CONSTANT.MASTER||
            location.pathname.match(new RegExp(`^${ROUTING_CONSTANT.MASTER}/[A-Za-z0-9-]+$`))
        );
    const isChapterPathActive =
        typeof to === "string" &&
        !chapterPathExcludes.includes(to) &&
        (
            location.pathname === ROUTING_CONSTANT.CHAPTER||
            location.pathname.match(new RegExp(`^${ROUTING_CONSTANT.CHAPTER}/[A-Za-z0-9-]+$`))
        );
    const isUserProfileCategoryPathActive =
        typeof to === "string" &&
        !userProfileCategoryPathExcludes.includes(to) &&
        (
            location.pathname === ROUTING_CONSTANT.USER_PROFILE_CATEGORY||
            location.pathname.match(new RegExp(`^${ROUTING_CONSTANT.USER_PROFILE_CATEGORY}/[A-Za-z0-9-]+$`))
        );
    const isMetricPathActive =
        typeof to === "string" &&
        !metricPathExcludes.includes(to) &&
        (
            location.pathname === ROUTING_CONSTANT.SUB_METRIC||
            location.pathname.match(new RegExp(`^${ROUTING_CONSTANT.SUB_METRIC}/[A-Za-z0-9-]+$`))
        );
    const isUserProfileSubCategoryPathActive =
        typeof to === "string" &&
        !userProfileSubCategoryPathExcludes.includes(to) &&
        (
            location.pathname === ROUTING_CONSTANT.USER_PROFILE_SUB_CATEGORY ||
            location.pathname.match(new RegExp(`^${ROUTING_CONSTANT.USER_PROFILE_SUB_CATEGORY}/[A-Za-z0-9-]+$`))
        );

    const isUserProfileQuestionPathActive = typeof to === "string" &&
        !userProfileQuestionPathExcludes.includes(to) &&
        (
            location.pathname === ROUTING_CONSTANT.USER_PROFILE_QUESTION||
            location.pathname.match(new RegExp(`^${ROUTING_CONSTANT.USER_PROFILE_QUESTION}/[A-Za-z0-9-]+$`))
        );

    const isCronJobPathActive = typeof to === "string" &&
        !cronJobPathExcludes.includes(to) &&
        (
            location.pathname === ROUTING_CONSTANT.CRON_JOB||
            location.pathname.match(new RegExp(`^${ROUTING_CONSTANT.CRON_JOB}/[A-Za-z0-9-]+$`))
        );
    const isGoalPathActive =
        typeof to === "string" &&
        !goalExcludes.includes(to) &&
        (
            location.pathname === ROUTING_CONSTANT.GOAL||
            location.pathname.match(new RegExp(`^${ROUTING_CONSTANT.GOAL}/[A-Za-z0-9-]+$`))
        );

    const isTermsOfUsePolicyActive = typeof to === "string" &&
        !termsOfUsePolicyExcludes.includes(to) &&
        (
            location.pathname === ROUTING_CONSTANT.TERMS_OF_USE_POLICY||
            location.pathname.match(new RegExp(`^${ROUTING_CONSTANT.TERMS_OF_USE_POLICY}/[A-Za-z0-9-]+$`))
        );
    const isPrivacyPolicyActive = typeof to === "string" &&
        !privacyPolicyExcludes.includes(to) &&
        (
            location.pathname === ROUTING_CONSTANT.PRIVACY_POLICY||
            location.pathname.match(new RegExp(`^${ROUTING_CONSTANT.PRIVACY_POLICY}/[A-Za-z0-9-]+$`))
        );

    // For top menu
    const match = to !== "/" && location.pathname === to;
    const { t } = useTranslation("menu");

    const LINK_CLASS = classNames("navigation-link", "navigation-link-pill", {
        collapsed: !!children && !isHorizontal,
        active: isHorizontal
            ? match
            : isToPathActive ||
            isUserPathActive ||
            isPromoCodePathActive ||
            isCategoryPathActive ||
            isSubCategoryPathActive ||
            isProductPathActive ||
            isSkuPathActive ||
            isIngredientPathActive ||
            isMarketingPathActive ||
            isDosagePathActive ||
            isBenefitPathActive ||
            isFaqPathActive ||
            isTrainerPathActive ||
            isClassPathActive ||
            isCoursePathActive ||
            isKeywordPathActive ||
            isHowItPathActive || isTermsOfUsePolicyActive ||
            isDeliveryPathActive || isPrivacyPolicyActive ||
            isMasterPathActive ||
            isChapterPathActive ||
            isBotPathActive ||
            isCourseFaqsPathActive ||
            isUserHardwareSpecificPathExcludes ||
            isUserMetricSpecificPathExcludes ||
            isUserMedicalSpecificPathExcludes ||
            isUserProfileCategoryPathActive || 
            isUserProfileSubCategoryPathActive || 
            isUserProfileQuestionPathActive || 
            isMetricPathActive ||
            isCronJobPathActive || 
            isMobilePathActive || 
            isUserDetailPathActive ||
            isInterestPathActive ||
            isInterestQuestionPathActive || isRecipeBundlePathActive || isNuggetsTagPathActive || isNuggetsPathActive || isCareerPathActive ||
            isLevelOneMappingPathActive || isAssessmentQuestionPathActive || isRecommendationRulePathActive || isRecipePathActive ||
            isGoalPathActive || isLevelZeroMappingPathActive || islabTestPathActive || isDevicePathActive || isAssessmentPathActive,
    });

    const INNER = (
        <>
            <span className="navigation-link-info">
                {icon && <Icon className="navigation-icon" icon={icon} />}
                {title && (
                    <span className="navigation-text">{t(title) as ReactNode}</span>
                )}
            </span>
            {(!!children || !!notification) && (
                <span className="navigation-link-extra">
                    {!!notification && (
                        <Icon
                            icon="Circle"
                            className={classNames(
                                "navigation-notification",
                                {
                                    [`text-${notification}`]: typeof notification === "string",
                                    "text-danger": typeof notification !== "string",
                                },
                                "animate__animated animate__heartBeat animate__infinite animate__slower"
                            )}
                        />
                    )}
                    {!!children && (
                        <Icon className="navigation-arrow" icon="ChevronRight" />
                    )}
                </span>
            )}
        </>
    );

    const WITHOUT_CHILD =
        !children &&
        !hide &&
        ((typeof to === "string" && ANCHOR_LINK_PATTERN.test(to) && (
            <NavHashLink className={LINK_CLASS} to={to} onClick={linkHandleClick}>
                {INNER}
            </NavHashLink>
        )) || (
                <NavLink
                    end
                    className={classNames(LINK_CLASS, { active: isToPathActive })}
                    to={`../${to}`}
                    onClick={linkHandleClick}
                >
                    {INNER}
                </NavLink>
            ));

    // Dropdown
    const dropdownRef = useRef(null);

    const dropdownButtonRef = useRef(null);
    const setButtonRef = useCallback((node: null, ref: (arg0: any) => any) => {
        dropdownButtonRef.current = node;
        return ref(node);
    }, []);

    const dropdownListRef = useRef(null);
    const setListRef = useCallback((node: null, ref: (arg0: any) => any) => {
        dropdownListRef.current = node;
        return ref(node);
    }, []);

    const [dropdownStatus, setDropdownStatus] = useState(false);

    const dropdownButtonHandleClick = () => {
        setDropdownStatus(!dropdownStatus);
    };

    // Clicking outside to close
    const closeMenu = useCallback(() => {
        setDropdownStatus(false);
    }, []);
    useEventOutside(dropdownRef, "mousedown", closeMenu);
    useEventOutside(dropdownRef, "touchstart", closeMenu);

    if (children) {
        // submenu && in header
        if (isHorizontal) {
            return (
                <Manager>
                    <li
                        ref={dropdownRef}
                        className={classNames("navigation-item", "dropdown", {
                            "navigation-item-more": isMore,
                        })}
                    >
                        <Reference>
                            {({ ref }) => (
                                <span
                                    // @ts-ignore
                                    ref={(node) => setButtonRef(node, ref)}
                                    id={`${rootId}__${id}--link`}
                                    className={LINK_CLASS}
                                    // data-bs-toggle='dropdown'
                                    // data-bs-target={`#${rootId}__${id}`}
                                    aria-expanded={dropdownStatus}
                                    aria-controls={`${rootId}__${id}`}
                                    role="button"
                                    tabIndex={-1}
                                    onClick={dropdownButtonHandleClick}
                                    onKeyDown={dropdownButtonHandleClick}
                                >
                                    {INNER}
                                </span>
                            )}
                        </Reference>
                        {dropdownStatus && (
                            <Popper
                                placement="bottom-start"
                                modifiers={[
                                    {
                                        name: "flip",
                                        options: {
                                            fallbackPlacements: [`bottom-end`, `bottom-start`],
                                        },
                                    },
                                ]}
                            >
                                {({ ref, style, placement }) => (
                                    <List
                                        // @ts-ignore
                                        ref={(node) => setListRef(node, ref)}
                                        style={style}
                                        data-placement={placement}
                                        id={`${rootId}__${id}`}
                                        className={classNames(
                                            "dropdown-menu",
                                            {
                                                "dropdown-menu-dark": darkModeStatus,
                                            },
                                            "show"
                                        )}
                                        ariaLabelledby={`${rootId}__${id}--link`}
                                        rootId={rootId}
                                        parentId={`${rootId}__${parentId}`}
                                        onMouseLeave={() => setDropdownStatus(false)}
                                    >
                                        {children}
                                    </List>
                                )}
                            </Popper>
                        )}
                    </li>
                </Manager>
            );
        }
        // submenu && in aside
        return (
            <li className="navigation-item">
                <span
                    id={`${rootId}__${id}--link`}
                    className={LINK_CLASS}
                    // data-bs-toggle='collapse'
                    // data-bs-target={`#${rootId}__${id}`}
                    aria-expanded={ACTIVE}
                    aria-controls={`${rootId}__${id}`}
                    role="button"
                    tabIndex={-1}
                    onClick={handleClick}
                    onKeyDown={handleClick}
                >
                    {INNER}
                </span>
                <Collapse isOpen={ACTIVE} isChildClone>
                    <List
                        id={`${rootId}__${id}`}
                        ariaLabelledby={`${rootId}__${id}--link`}
                        rootId={rootId}
                        parentId={`${rootId}__${parentId}`}
                        onMouseLeave={closeMenu}
                    >
                        {children}
                    </List>
                </Collapse>
            </li>
        );
    }
    // without submenu
    return <li className="navigation-item">{WITHOUT_CHILD}</li>;
};
Item.propTypes = {
    children: PropTypes.node,
    to: PropTypes.string,
    title: PropTypes.string,
    icon: PropTypes.string,
    id: PropTypes.string,
    parentId: PropTypes.string,
    rootId: PropTypes.string.isRequired,
    isHorizontal: PropTypes.bool,
    notification: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    isMore: PropTypes.bool,
    hide: PropTypes.bool,
};
Item.defaultProps = {
    children: null,
    to: undefined,
    title: undefined,
    icon: undefined,
    id: undefined,
    parentId: undefined,
    isHorizontal: false,
    notification: false,
    isMore: false,
    hide: false,
};

export default memo(Item);
