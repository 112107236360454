import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import PropTypes from "prop-types";
import { DashboardService } from "../services/dashboard.service";

export interface IDataContextProps {
  faqData: unknown;
  metricData: unknown;
  trainerData: unknown;
  categoryData: unknown;
  subCategoryData: unknown;
  dosageAssistanceData: unknown;
  marketData: unknown;
  benefitData: unknown;
  keywordData: unknown;
  taggingLabel: unknown;
  ingredientData: unknown;
  howItWorkData: unknown;
  classData:unknown;
  chapterData:unknown;
  courseData:unknown;
  courseFaqData:unknown;
  deliveryOption:unknown;
  masterData:unknown;
  productData:unknown;
  userProfileCategoryData:unknown;
  userProfileSubCategoryData:unknown;
  userProfileQuestionData:unknown;
  mobileScreenData:unknown;
  userDetailScreen:unknown;
  interest:unknown;
  interestQuestion:unknown;
  levelOneMappingData:unknown;
  goalData:unknown;
  usersData:unknown;
  levelZeroMappingData:unknown;
  labTestData:unknown;
  userDevicesData:unknown;
  devicesData:unknown;
  assessmentData:unknown;
  assessmentQuestionData:unknown;
  recommendationRuleData:unknown;
  recipeData:unknown;
  recipeBundleData:unknown;
  nuggetTagData:unknown;
  nuggetData:unknown;
  privacyPolicyData:unknown;
  termsOfUsePolicyData:unknown;
  deviceCategoryData:unknown;
  deviceSubCategoryData:unknown;
  deviceModelData:unknown;
  careerData:unknown;

  setFaqData?(...args: unknown[]): unknown;
  setMetricData?(...args: unknown[]): unknown;
  setTrainerData?(...args: unknown[]): unknown;
  setCategoryData?(...args: unknown[]): unknown;
  setSubCategoryData?(...args: unknown[]): unknown;
  setDosageAssistanceData?(...args: unknown[]): unknown;
  setMarketData?(...args: unknown[]): unknown;
  setBenefitData?(...args: unknown[]): unknown;
  setKeywordData?(...args: unknown[]): unknown;
  setSkuVariant?(...args: unknown[]): unknown;
  setIngredientData?(...args: unknown[]): unknown;
  setHowItWorkData?(...args: unknown[]): unknown;
  setTaggingLabel?(...args: unknown[]): unknown;
  setClassData?(...args: unknown[]): unknown;
  setChapterData?(...args: unknown[]): unknown;
  setCourseData?(...args: unknown[]): unknown;
  setCourseFaqData?(...args: unknown[]): unknown;
  setDeliveryOption?(...args: unknown[]): unknown;
  setMasterData?(...args: unknown[]): unknown;
  setProductData?(...args: unknown[]): unknown;
  setUserProfileCategoryData?(...args: unknown[]): unknown;
  setUserProfileSubCategoryData?(...args: unknown[]): unknown;
  setUserProfileQuestionData?(...args: unknown[]): unknown;
  serMobileScreenData?(...args: unknown[]): unknown;
  setUserDetailScreenData?(...args: unknown[]): unknown;
  setInterestData?(...args: unknown[]): unknown;
  setInterestQuestionData?(...args: unknown[]): unknown;
  setLevelOneMappingData?(...args: unknown[]): unknown;
  setGoalData?(...args: unknown[]): unknown;
  setUsersData?(...args: unknown[]): unknown;
  setLevelZeroMappingData?(...args: unknown[]): unknown;
  setLabTestData?(...args:unknown[]): unknown;
  setUserDevicesData?(...args:unknown[]): unknown;
  setDevicesData?(...args:unknown[]): unknown;
  setAssessmentData?(...args:unknown[]): unknown;
  setAssessmentQuestionData?(...args:unknown[]): unknown;
  setRecommendationRuleData?(...args:unknown[]): unknown;
  setRecipeData?(...args:unknown[]): unknown;
  setRecipeBundleData?(...args:unknown[]): unknown;
  setNuggetTagData?(...args:unknown[]): unknown;
  setNuggetData?(...args:unknown[]): unknown;
  setPrivacyPolicyData?(...args:unknown[]): unknown;
  setTermsOfUsePolicyData?(...args:unknown[]): unknown;
  setDeviceCategoryData?(...args:unknown[]): unknown;
  setDeviceSubCategoryData?(...args:unknown[]): unknown;
  setDeviceModelData?(...args:unknown[]): unknown;
  setCareerData?(...args:unknown[]): unknown;
}
const DataContext = createContext<IDataContextProps>({} as IDataContextProps);

interface IDataContextProviderProps {
  children: ReactNode;
}
export const DataContextProvider: FC<IDataContextProviderProps> = ({
  children,
}) => {
  const [faqData, setFaqData] = useState<any>([]);
  const [categoryData, setCategoryData] = useState<any>([]);
  const [subCategoryData, setSubCategoryData] = useState<any>([]);
  const [dosageAssistanceData, setDosageAssistanceData] = useState<any>([]);
  const [marketData, setMarketData] = useState<any>([]);
  const [benefitData, setBenefitData] = useState<any>([]);
  const [keywordData, setKeywordData] = useState<any>([]);
  const [taggingLabel, setTaggingLabel] = useState<any>([]);
  const [ingredientData, setIngredientData] = useState<any>([]);
  const [howItWorkData, setHowItWorkData] = useState<any>([]);
  const [trainerData, setTrainerData] = useState<any>([]);
  const [classData, setClassData] = useState<any>([]);
  const [chapterData, setChapterData] = useState<any>([]);
  const [courseData, setCourseData] = useState<any>([]);
  const [metricData, setMetricData] = useState<any>([]);
  const [deliveryOption, setDeliveryOption] = useState<any>([]);
  const [masterData, setMasterData] = useState<any>([]);
  const [productData, setProductData] = useState<any>([]);
  const [userProfileCategoryData, setUserProfileCategoryData] = useState<any>([]);
  const [userProfileSubCategoryData, setUserProfileSubCategoryData] = useState<any>([]);
  const [userProfileQuestionData, setUserProfileQuestionData] = useState<any>([]);
  const [courseFaqData, setCourseFaqData] = useState<any>([]);
  const [mobileScreenData, serMobileScreenData] = useState<any>([]);
  const [userDetailScreen, setUserDetailScreenData] = useState<any>([]);
  const [interest, setInterestData] = useState<any>([]);
  const [interestQuestion, setInterestQuestionData] = useState<any>([]);
  const [levelOneMappingData, setLevelOneMappingData] = useState<any>([]);
  const [goalData, setGoalData] = useState<any>([]);
  const [usersData, setUsersData] = useState<any>([]);
  const [levelZeroMappingData, setLevelZeroMappingData] = useState<any>([]);
  const [labTestData, setLabTestData] = useState<any>([]);
  const [userDevicesData, setUserDevicesData] = useState<any>([]);
  const [devicesData, setDevicesData] = useState<any>([]);
  const [assessmentData, setAssessmentData] = useState<any>([]);
  const [assessmentQuestionData, setAssessmentQuestionData] = useState<any>([]);
  const [recommendationRuleData, setRecommendationRuleData] = useState<any>([]);
  const [recipeData, setRecipeData] = useState<any>([]);
  const [recipeBundleData, setRecipeBundleData] = useState<any>([]);
  const [nuggetTagData, setNuggetTagData] = useState<any>([]);
  const [nuggetData, setNuggetData] = useState<any>([]);
  const [privacyPolicyData, setPrivacyPolicyData] = useState<any>([]);
  const [termsOfUsePolicyData, setTermsOfUsePolicyData] = useState<any>([]);
  const [deviceCategoryData, setDeviceCategoryData] = useState<any>([]);
  const [deviceSubCategoryData, setDeviceSubCategoryData] = useState<any>([]);
  const [deviceModelData, setDeviceModelData] = useState<any>([]);
  const [careerData, setCareerData] = useState<any>([]);

  const value = useMemo(
    () => ({
      faqData,
      metricData,
      categoryData,
      subCategoryData,
      dosageAssistanceData,
      marketData,
      benefitData,
      keywordData,
      taggingLabel,
      ingredientData,
      howItWorkData,
      trainerData,
      classData,
      chapterData,
      courseData,
      courseFaqData,
      deliveryOption,
      masterData,
      productData,
      userProfileCategoryData,
      userProfileSubCategoryData,
      userProfileQuestionData,
      mobileScreenData,
      userDetailScreen,
      interest,
      interestQuestion,
      levelOneMappingData,
      goalData,
      usersData,
      levelZeroMappingData,
      labTestData,
      userDevicesData,
      devicesData,
      assessmentData,
      assessmentQuestionData,
      recommendationRuleData,
      recipeData,
      recipeBundleData,
      nuggetTagData,
      nuggetData,
      privacyPolicyData,
      termsOfUsePolicyData,
      deviceCategoryData,
      deviceSubCategoryData,
      deviceModelData,
      careerData,

      setFaqData,
      setMetricData,
      setCategoryData,
      setSubCategoryData,
      setDosageAssistanceData,
      setMarketData,
      setBenefitData,
      setKeywordData,
      setTaggingLabel,
      setIngredientData,
      setHowItWorkData,
      setTrainerData,
      setClassData,
      setChapterData,
      setCourseData,
      setCourseFaqData,
      setDeliveryOption,
      setMasterData,
      setProductData,
      setUserProfileCategoryData,
      setUserProfileSubCategoryData,
      setUserProfileQuestionData,
      serMobileScreenData,
      setUserDetailScreenData,
      setInterestData,
      setInterestQuestionData,
      setLevelOneMappingData,
      setGoalData,
      setUsersData,
      setLevelZeroMappingData,
      setLabTestData,
      setUserDevicesData,
      setDevicesData,
      setAssessmentData,
      setAssessmentQuestionData,
      setRecommendationRuleData,
      setRecipeData,
      setRecipeBundleData,
      setNuggetTagData,
      setNuggetData,
      setPrivacyPolicyData,
      setTermsOfUsePolicyData,
      setDeviceCategoryData,
      setDeviceSubCategoryData,
      setDeviceModelData,
      setCareerData,
 
    }),
    [
      faqData,
      metricData,
      categoryData,
      subCategoryData,
      dosageAssistanceData,
      marketData,
      benefitData,
      keywordData,
      taggingLabel,
      ingredientData,
      howItWorkData,
      trainerData,
      classData,
      chapterData,
      courseData,
      courseFaqData,
      deliveryOption,
      masterData,
      productData,
      userProfileCategoryData,
      userProfileSubCategoryData,
      userProfileQuestionData,
      mobileScreenData,
      userDetailScreen,
      interest,
      interestQuestion,
      levelOneMappingData,
      goalData,
      usersData,
      levelZeroMappingData,
      labTestData,
      userDevicesData,
      devicesData,
      assessmentData,
      assessmentQuestionData,
      recommendationRuleData,
      recipeData,
      recipeBundleData,
      nuggetTagData,
      nuggetData,
      privacyPolicyData,
      termsOfUsePolicyData,
      deviceCategoryData,
      deviceSubCategoryData,
      deviceModelData,
      careerData,
    ]
  );
  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};

DataContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DataContext;
