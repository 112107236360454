import React, { useState, useEffect } from "react";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import { CONSTANT } from "../../../core/static-constant";
import { toast } from "react-toastify";
import PaginationButtons from "../../../components/PaginationButtons";
import { logoutUser } from "../../../core/auth.service";
import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader,
} from "../../../components/bootstrap/Modal";
import { UserHardwareSpecificService } from "../../../services/hardwareSpecific.service";

const ViewHardwareSpecific = (props: any) => {
    const { delet, edit, decodedJwt, TotalRecords, HardwareSpecific, handleChangeRowsPerPage, handleChangePage, perPage, CurrentPage } = props;
    const [isLoading, setShowLoading] = useState(false);
    const [hardwareSpecific, setHardwareSpecificData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [hardwareSpecificDataById, setHardwareSpecificDataById] = useState<any>();
    const [hardwareSpecificDataByIdResult, setHardwareSpecificDataByIdResult] = useState<any>();
    const [pdfDataByIdResult, setPdfDataByIdResult] = useState<any>();

    const toggleModal = async (id: any) => {
        try {
            setShowLoading(true);
            let data: any = await UserHardwareSpecificService.getHardwareSpecificById(id);
            setShowLoading(false);
            if (data && data.status === CONSTANT.SUCCESS) {
                const responseData = data?.data?.hardwareSpecificDetail?.data || {};
                const responseDataResult = data?.data?.hardwareSpecificDetail || {};
                const pdfData = data?.data?.hardwareSpecificDetail?.pdf || {};
                setHardwareSpecificDataById(responseData);
                setHardwareSpecificDataByIdResult(responseDataResult);
                setPdfDataByIdResult(pdfData);
            } else {
                toast.error(data.message);
            }
        } catch (error: any) {
            if (error?.response?.data?.message !== 'Something went wrong, please try again later!') {
                toast.error(error?.response?.data?.message);
            }
            setShowLoading(false);
            logoutUser({ error });
            toast.error(error.response.data.error);
        }
        setIsModalOpen(!isModalOpen);
    };
    const closeModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        setHardwareSpecificData(HardwareSpecific);
    }, [HardwareSpecific]);

    const getTableSubdata = (objectData: any, index: number) => {
        return (
            <div className="container">
                <table className="table border">
                    {
                        Object.keys(objectData).map((key: string, j: number) => {
                            return (
                                <React.Fragment>
                                    {Array.isArray(objectData?.[key]) && objectData?.[key]?.length > 0 ?
                                        getTabledata(objectData?.[key], key) :
                                        <>
                                            <tr className="row" key={j}>
                                                <td className="col-sm-8">
                                                    <p className="productdetail">{key} :</p>
                                                </td>
                                                <td className="col-sm-4 text-center">
                                                    <p className="productdetail">
                                                        {Array.isArray(objectData?.[key]) && objectData?.[key]?.length > 0
                                                            ? objectData[key].map((item: any) => item.value).join(", ")
                                                            : typeof objectData?.[key] === "object" && objectData?.[key] !== null ? JSON.stringify(objectData?.[key]) : objectData?.[key]
                                                        }
                                                    </p>
                                                </td>
                                            </tr>
                                        </>
                                    }
                                </React.Fragment>
                            )
                        })
                    }
                </table>

            </div>
        )
    }
    const getTabledata = (arrayData: any[], keyName: string) => {
        console.log('arrayData',arrayData);
        return (
            <div className="container">
                <div className="fw-bolder">{keyName}</div>
                {arrayData &&
                    arrayData.map((ele: any, index: any) => {
                        return (
                            <div key={index}>
                                {getTableSubdata(ele, index)}
                            </div>
                        )
                    })}
            </div>
        );

    }

    const renderTableRows = (data: any) => {
        console.log('data',data);
        return Object.keys(data).map((key, index) => {
            const value = data[key];
            if (typeof value === 'object' && value !== null) {
                return (
                    <React.Fragment key={index}>
                        <tr>
                            <td className="col-sm-9"><strong>{key}:</strong></td>
                            <td className="col-sm-3"></td>
                        </tr>
                        {renderTableRows(value)}
                    </React.Fragment>
                );
            } else {
                if (Array.isArray(value)) {
                    return value.map((item, subIndex) => (
                        <tr key={`${index}-${subIndex}`}>
                            <td className="col-sm-9"><p>{key}:</p></td>
                            <td className="col-sm-3 text-end">{item}</td>
                        </tr>
                    ));
                } else {
                    return (
                        <tr key={index}>
                            <td className="col-sm-3 text-start">{value}</td>
                            {/* <td className="col-sm-9"><p>{key}:</p></td> */}
                        </tr>
                    );
                }
            }
        });
    };

    const renderData = (data: any) => {
        if (Array.isArray(data)) {
            return (
                <div>
                    <table className="table">
                        <tbody>
                            {renderTableRows(data)}
                        </tbody>
                    </table>
                </div>
            )
        } else if (typeof data === 'object' && data !== null) {
            return (
                <table className="table">
                    <tbody>
                        {renderTableRows(data)}
                    </tbody>
                </table>
            );
        } else {
            return null;
        }
    };


    return (
        <>
            <Card stretch>
                <CardBody className="table-responsive">
                    <table className="table table-modern table-hover">
                        <thead>
                            <tr>
                                <th>Time</th>
                                <th>Hardware Type</th>
                                <th>Pdf ID</th>
                                <th className="text-end">Action</th>
                            </tr>
                        </thead>

                        {isLoading ? (
                            <>
                                <tbody>
                                    <tr>
                                        <td colSpan={4}>
                                            <div className="d-flex justify-content-center">
                                                <div className="spinner-border" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </>
                        ) : HardwareSpecific.length === 0 ? (
                            <tbody>
                                <tr>
                                    <td colSpan={6}>
                                        <div className="text-center fw-bold">No Data Found</div>
                                    </td>
                                </tr>
                            </tbody>
                        ) : (
                            <tbody>
                                {hardwareSpecific &&
                                    hardwareSpecific.map((ele: any, i: any) => {
                                        return (
                                            <tr key={i}>
                                                <th className="text-muted">{ele.recordTimestamp || "N/A"}</th>
                                                <th className="text-muted">
                                                    {ele && ele?.hardwareId && ele?.hardwareId?.hardwareType || 'N/A'} 
                                                </th>
                                                <th className="text-muted">
                                                    {ele?.hardwareId?.hardwareType === 'Pdf' ? ele?.pdfId : 'N/A'}
                                                </th>
                                                <th className="text-muted">
                                                    <div className="d-flex align-items-center justify-content-end gap-2">
                                                        <Button
                                                            icon="Visibility"
                                                            className="eye-icon"
                                                            data-bs-toggle="tooltip"
                                                            data-bs-placement="bottom"
                                                            title="View Details"
                                                            onClick={() => toggleModal(ele._id)}
                                                        ></Button>
                                                    </div>
                                                </th>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        )}
                    </table>
                </CardBody>
                <PaginationButtons
                    count={TotalRecords}
                    label="Records"
                    setCurrentPage={handleChangePage}
                    currentPage={CurrentPage}
                    perPage={perPage}
                    setPerPage={handleChangeRowsPerPage}
                />

                {isModalOpen && (
                    <Modal
                        setIsOpen={closeModal}
                        isOpen={isModalOpen}
                        toggle={closeModal}

                    >
                        {/* <ModalHeader className="headertext d-flex justify-content-between fw-bolder mt-3"> */}
                        {hardwareSpecificDataByIdResult && (
                            <>
                                <div className="d-flex m-3 fw-bolder headertext justify-content-between">
                                    <p>HardwareType: {hardwareSpecificDataByIdResult?.hardwareId?.hardwareType}</p>
                                    <p>{hardwareSpecificDataByIdResult?.hardwareId?.hardwareType === CONSTANT.PDF
                                        && `PdfID: ${hardwareSpecificDataByIdResult?.pdfId}`}</p>
                                    <span className="text-uppercase">Time: {hardwareSpecificDataByIdResult?.recordTimestamp}</span>
                                </div>
                                <div className="mx-3 my-0">
                                    <p className="text-uppercase fw-bolder">
                                        {hardwareSpecificDataByIdResult?.hardwareId?.hardwareType === CONSTANT.PDF
                                            ? 'PDF Report Details'
                                            : 'Hardware Specific Details'}
                                    </p>
                                </div>
                            </>
                        )}

                        {/* </ModalHeader> */}
                        <ModalBody>
                            <div className="container">
                                {hardwareSpecificDataById &&
                                    Object.keys(hardwareSpecificDataById).map((ele: any, index: any) => {
                                        return (
                                            <div key={ele}>
                                                {Array.isArray(hardwareSpecificDataById?.[ele]) && hardwareSpecificDataById?.[ele]?.length > 0 ? getTabledata(hardwareSpecificDataById?.[ele], ele) : !Array.isArray(hardwareSpecificDataById?.[ele]) && typeof hardwareSpecificDataById?.[ele] === "object" ? getTableSubdata(hardwareSpecificDataById?.[ele], index) :
                                                    <>
                                                        {
                                                            <table className="table" >
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="col-sm-9">
                                                                            <p > {ele} :</p>
                                                                        </td>
                                                                        <td className="col-sm-3 text-end">
                                                                            <p>{hardwareSpecificDataById[ele] !== undefined ? hardwareSpecificDataById[ele] : '0'}</p>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        }
                                                    </>
                                                }
                                            </div>
                                        )
                                    })
                                }
                                {pdfDataByIdResult && Object.keys(pdfDataByIdResult).map((ele, index) => {
                                    const data = pdfDataByIdResult[ele];
                                    return (
                                        <div key={ele}>
                                            {Array.isArray(data) && data.length > 0 ? (
                                                renderData(data)
                                            ) : (typeof data === 'object' && data !== null) ? (
                                                renderData(data)
                                            ) : (
                                                <table className="table">
                                                    <tbody>
                                                        <tr>
                                                            <td className="col-sm-9"><strong>{ele} :</strong></td>
                                                            <td className="col-sm-3 text-end"><p>{data !== undefined ? data : '0'}</p></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={closeModal}>
                                Close
                            </Button>
                        </ModalFooter>
                    </Modal>
                )}

            </Card>
        </>
    );
};

export default ViewHardwareSpecific;
