import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { CONSTANT } from "../../../core/static-constant";
import { CareerService } from "../../../services/career.service";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import {
    ROUTING_CONSTANT,
} from "../../../core/constant";
import * as yup from "yup";
import DataContext from "../../../contexts/dataContext";
import Loader from "../../Loader/loader";
import { decryptData } from "../../../core/auth.service";
import "../User/user.scss";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const AddEditCareer = () => {
    const { setCareerData, careerData }: any = useContext(DataContext);
    const params = useParams();
    const navigate = useNavigate();
    const [CareerId, setCareerId] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const [CareerAllData, setCareerAllData] = useState<any>({
        jobTitle: "",
        openings: "",
        jobs: []
    });

    const validationSchema = yup.object().shape({
        jobTitle: yup.string().required("required"),
    });
    const validateJobs = (jobs: any[]) => {
        return jobs.every(job =>
            ["title", "location", "keyResponsibility", "whatYouBring", "about", "role", "niceToHave", "WhyJoin"].every(
                field => job[field]?.trim() !== ""
            )
        );
    };

    const [isInvalidForm, setIsInvalidForm] = useState(false);

    const { values, handleChange, handleSubmit, setValues } = useFormik({
        initialValues: { ...CareerAllData },
        validationSchema,
        onSubmit: async (values, action) => {
            try {
                if (!validateJobs(CareerAllData.jobs)) {
                    return;
                } else {
                    const filteredJobs = CareerAllData.jobs.filter((job: any) => {
                        return Object.values(job).some(
                            (value: any) => value.trim().replace(/<[^>]*>?/gm, "") !== ""
                        );
                    });
                    const filteredValues: any = {
                        ...values,
                        jobs: filteredJobs,
                        openings: filteredJobs.length,
                    };
                    setIsLoading(true);
                    const data = CareerId ? await CareerService.updateCareerById(CareerId, filteredValues) : await CareerService.addCareer(filteredValues);
                    setIsLoading(false);
                    if (data && data.status === CONSTANT.SUCCESS) {
                        toast.success(data.message);
                        navigate(ROUTING_CONSTANT.CAREER_VIEW);
                        action.resetForm();

                        const { _id, name } = data.data;

                        if (CareerId) {
                            const existingIndex = careerData.findIndex((item: any) => item._id === _id);
                            if (existingIndex !== -1) {
                                setCareerData((prevData: any) => {
                                    const newData = [...prevData];
                                    newData[existingIndex] = { _id, name };
                                    return newData;
                                });
                            }
                        }
                    } else {
                        toast.error(data.message);
                    }
                }
            } catch (error: any) {
                toast.error(error?.response?.data?.error);
                setIsLoading(false);
            }
        },
    });

    useEffect(() => {
        const init = async () => {
            if (params && decryptData(params.id)) {
                setCareerId(decryptData(params.id));
                getCareerById(decryptData(params.id));
            }
        };
        init();
    }, []);

    const getCareerById = async (id: any) => {
        try {
            setIsLoading(true);
            const response = await CareerService.getCareerById(id);
            setIsLoading(false);
            if (response.status === CONSTANT.SUCCESS) {
                setCareerAllData(response?.data?.Career);
                setValues({ ...response?.data?.Career });
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.error);
            setIsLoading(false);
        }
    };

    const addJob = () => {
        if (!validateJobs(CareerAllData.jobs)) {
            toast.error(CONSTANT.VALID_OPTIONS);
            return;
        } else {
            const newJob = {
                title: "",
                location: "",
                keyResponsibility: "",
                whatYouBring: "",
                niceToHave: "",
                WhyJoin: "",
                role: "",
                about: "",
            };
            setCareerAllData((prevState: any) => ({
                ...prevState,
                jobs: [...prevState.jobs, newJob],
            }));
        }
    };

    const removeJob = (index: number) => {
        setCareerAllData((prevState: any) => ({
            ...prevState,
            jobs: prevState.jobs.filter((_: any, i: number) => i !== index),
        }));
    };

    const updateJobField = (index: number, field: string, value: string) => {
        setCareerAllData((prevState: any) => {
            const updatedJobs = [...prevState.jobs];
            updatedJobs[index][field] = value;
            return { ...prevState, jobs: updatedJobs };
        });
    };

    const onCancelClick = () => {
        navigate(ROUTING_CONSTANT.CAREER_VIEW);
    };

    const handleSave = () => {
        setIsInvalidForm(true);
    };

    function style() {
        return {
            control: (baseStyles: any) => ({
                ...baseStyles,
                borderRadius: "10px",
            }),
        };
    };

    const customStyles = style();
    const toolbarOptions = [
        [{ header: [1, 2, 3, false] }],
        ["bold", "italic", "underline"],
        [{ color: [] }, { background: [] }],
        [{ align: [] }],
        ["link", "image"],
        ["clean"],
        [{ list: "ordered" }, { list: "bullet" }],
    ];
    
    const modules = {
        toolbar: {
            container: toolbarOptions,
        },
    };
    const formats = [
        "list",
        "bullet",
        "alpha",
        "roman",
        "bold",
        "italic",
        "underline",
        "link",
        "header",
        "color",
        "background",
        "align",
        "image",
    ];

    return (
        <div className="page container-xxl align-items-center">
            {isLoading && (
                <div className="absolute top-[50%] z-50 left-[50%]  transform translate-x-[-50%] translate-y-[-50%]">
                    <Loader />
                </div>

            )}
            <Card className="w-100">
                <CardBody>
                    <h3 className="text-center mt-3 mb-5 text-primary">
                        <strong>
                            {CareerId ? CONSTANT.UPDATE : CONSTANT.ADD} Career
                        </strong>
                    </h3>
                    <form onSubmit={handleSubmit}>
                        <div className="row g-4">
                            <div className="col-sm-12 col-md-12">
                                <label className="form-labels">Career Name*</label>

                                <FormGroup id="jobTitle">
                                    <Input
                                        placeholder="Career Name"
                                        className={`${isInvalidForm && !values.jobTitle
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="jobTitle"
                                        value={values.jobTitle}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.jobTitle && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.NAME}</small>
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 mt-3">
                            <FormGroup id="options">
                                <>
                                    <label className="form-labels">Add Jobs</label>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-plus-circle sku-icon cursor-pointer"
                                        viewBox="0 0 16 16"
                                        onClick={addJob}
                                    >
                                        <path
                                            d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                        <path
                                            d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                    </svg>
                                </>
                            </FormGroup>
                            {CareerAllData?.jobs.map((job: any, index: number) => (
                                <div key={index} className="col-sm-12 col-md-12">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h3>Job {index + 1}</h3>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            className="bi bi-dash-circle sku-icon cursor-pointer mt-1"
                                            viewBox="0 0 16 16"
                                            onClick={() => removeJob(index)}
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zm-3.5 7a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5z" />
                                        </svg>
                                    </div>
                                    <div style={{ marginBottom: "50px" }}>
                                        <label className="form-labels">
                                            Title:
                                        </label>
                                        <ReactQuill
                                            modules={modules}
                                            formats={formats}
                                            value={job.title}
                                            onChange={(value: string) => updateJobField(index, "title", value)}
                                            className={`${isInvalidForm && (!job.title || job.title.trim().replace(/<[^>]*>?/gm, "") === "")
                                                ? "border border-danger"
                                                : "input-form"
                                                }`}
                                        />
                                    </div>

                                    <div style={{ marginBottom: "50px" }}>
                                        <label className="form-labels">
                                            Location:
                                        </label>
                                        <ReactQuill
                                            modules={modules}
                                            formats={formats}
                                            value={job.location}
                                            onChange={(e: any) =>
                                                updateJobField(index, "location", e)
                                            }
                                            className={`${isInvalidForm && !job?.location
                                                ? "border border-danger"
                                                : "input-form"
                                                }`}
                                        />
                                    </div>
                                    <div style={{ marginBottom: "50px" }}>
                                        <label className="form-labels">
                                            Key Responsibility:
                                        </label>
                                        <ReactQuill
                                            value={job.keyResponsibility}
                                            style={{ height: "300px" }}
                                            modules={modules}
                                            formats={formats}
                                            onChange={(e: any) =>
                                                updateJobField(index, "keyResponsibility", e)
                                            }
                                            className={`${isInvalidForm && !job?.keyResponsibility
                                                ? "border border-danger"
                                                : "input-form"
                                                }`}
                                        />
                                    </div>
                                    <div style={{ marginBottom: "50px" }}>
                                        <label className="form-labels">
                                            What You Bring:
                                        </label>
                                        <ReactQuill
                                            style={{ height: "300px" }}
                                            modules={modules}
                                            formats={formats}
                                            value={job.whatYouBring}
                                            onChange={(e: any) =>
                                                updateJobField(index, "whatYouBring", e)
                                            }
                                            className={`${isInvalidForm && !job?.whatYouBring
                                                ? "border border-danger"
                                                : "input-form"
                                                }`}
                                        />
                                    </div>
                                    <div style={{ marginBottom: "50px" }}>
                                        <label className="form-labels">
                                            Nice to Have:
                                        </label>
                                        <ReactQuill
                                            style={{ height: "300px" }}
                                            modules={modules}
                                            formats={formats}
                                            value={job.niceToHave}
                                            onChange={(e: any) =>
                                                updateJobField(index, "niceToHave", e)
                                            }
                                            className={`${isInvalidForm && !job?.niceToHave
                                                ? "border border-danger"
                                                : "input-form"
                                                }`}
                                        />
                                    </div>
                                    <div style={{ marginBottom: "50px" }}>
                                        <label className="form-labels">
                                            Why Join:
                                        </label>
                                        <ReactQuill
                                            style={{ height: "300px" }}
                                            modules={modules}
                                            formats={formats}
                                            value={job.WhyJoin}
                                            onChange={(e: any) =>
                                                updateJobField(index, "WhyJoin", e)
                                            }
                                            className={`${isInvalidForm && !job?.WhyJoin
                                                ? "border border-danger"
                                                : "input-form"
                                                }`}
                                        />
                                    </div>
                                    <div style={{ marginBottom: "50px" }}>
                                        <label className="form-labels">
                                            Role:
                                        </label>
                                        <ReactQuill
                                            style={{ height: "300px" }}
                                            modules={modules}
                                            formats={formats}
                                            value={job.role}
                                            onChange={(e: any) => updateJobField(index, "role", e)}
                                            className={`${isInvalidForm && !job?.role
                                                ? "border border-danger"
                                                : "input-form"
                                                }`}
                                        />
                                    </div>
                                    <div style={{ marginBottom: "50px" }}>
                                        <label className="form-labels">
                                            About:
                                        </label>
                                        <ReactQuill
                                            style={{ height: "300px" }}
                                            modules={modules}
                                            formats={formats}
                                            value={job.about}
                                            onChange={(e: any) => updateJobField(index, "about", e)}
                                            className={`${isInvalidForm && !job?.about
                                                ? "border border-danger"
                                                : "input-form"
                                                }`}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div>
                            <Button
                                type="submit"
                                className={`w-25 mt-5 process-btn ${isLoading ? "disabled-button" : "bg-info text-white"
                                    }`}
                                onClick={handleSave}
                                isDisable={isLoading}
                            >
                                {CareerId ? CONSTANT.UPDATE : CONSTANT.ADD} Career
                            </Button>
                            <Button
                                type="button"
                                color="danger"
                                className="w-25 mt-5 cancel-btn float-end"
                                onClick={onCancelClick}
                            >
                                Cancel
                            </Button>
                        </div>
                    </form>
                </CardBody>
            </Card>
        </div >
    );
};

export default AddEditCareer;
